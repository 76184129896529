import React from "react"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import keywords from "../../constants/keywords"
import SoftwareCapabilitesHero from "../../components/SoftwareCapabilites"
import ImageTitleDescriptionBullets from "../../components/ImageTitleDescriptionBullets"
import WebsitesImage from "../../assets/images/SoftwareDevelopmentImages/Video-conference-with-client.jpg" 
import MobileApplicationsImage from "../../assets/images/SoftwareDevelopmentImages/Man-Holding-Phone-With-App.jpg"
import WebApplicationsImage from "../../assets/images/SoftwareDevelopmentImages/Man-Using-Laptop-In-Building.jpg" 
import MiddlewareScriptsImage from "../../assets/images/SoftwareDevelopmentImages/Discussing-the-project-at-the-office.jpg"
import ECommerceWebsitesImage from "../../assets/images/SoftwareDevelopmentImages/Two-People-Shopping-On-Laptop.jpg" 
import IntegrationServicesImage from "../../assets/images/SoftwareDevelopmentImages/Hotel-staff-and-guest.jpg" 

// const LoadableParallax = loadable(() => import('../components/parallax/homeParallax'))

const SoftwareDevelopmentServicesPage = () => {

  // let keywords = keywords.home.keywords
  // console.log(keywords.home.keywords)
  return (
    <>

      <Layout black={false}>

        <SEO
          title={"Software Development | Differnt Systems"}
          description={"Differnt crafts custom software solutions for businesses, including websites, mobile apps, web applications, e-commerce platforms, and seamless integrations."}
          customKeywords={keywords.home.keywords}
        />
        <div className="offcanvas-wrap">
          <SoftwareCapabilitesHero/>
          <ImageTitleDescriptionBullets 
            id='websites'
            featureImage={WebsitesImage}
            title="Websites"
            description="In today's market, there's no shortage of powerful DIY website solutions. However, as your business expands, relying on third-party platforms poses challenges. Without full control or ownership of code, features, or quality, you're restricted. Users now demand inspiring designs, empowering features, real-time updates, and market agility. Our team leverages cutting-edge technologies to deliver flexible solutions tailored to your specific business."
            bullet1="Customize Features Specific To Your Industry"
            bullet2="Content Management Options to Suit Your Workflow"
            bullet3="Personalize Every Detail of Your Users' Experience"
            subTitle="" 
          />
          <ImageTitleDescriptionBullets 
            id='mobile-apps'
            featureImage={MobileApplicationsImage}
            title="Mobile Applications"
            description="Deliver unmatched convenience, granting users access to vital services and information from their pocket. Beyond convenience, enable heightened engagement through push notifications, streamline processes with cloud services, and cultivate deeper customer connections by utilizing location, health, and other sensor data from internal or nearby IoT devices."
            bullet1="Build a Following with Social/Gamification Features"
            bullet2="Integrate AI To Provide Better Insights and Personalization"
            bullet3="Utilize Devices' Cameras to Provide AR Experiences"
            subTitle="" 
            flip={true}
          />
          <ImageTitleDescriptionBullets 
            id='web-apps'
            featureImage={WebApplicationsImage}
            title="Web Applications"
            description="Effortlessly deploy custom software to any web browser on your current device(s), eliminating the need for installations or hardware upgrades. Seamlessly distribute updates to all users or specific groups, ensuring universal access to the latest features at scale. Enjoy enhanced engagement, scalability, and usability with universal compatibility across devices."
            bullet1="Instantly Push Updates to All Users"
            bullet2="Deliver Your Application to Devices of All Types and Sizes"
            bullet3="Provide Access Without Requiring Software Downloads"
            subTitle="" 
          />
          <ImageTitleDescriptionBullets 
            id='middleware'
            featureImage={MiddlewareScriptsImage}
            title="Middleware Scripts"
            description="Not all software necessitates an interface or human interaction. Enable triggers, timed events, or other customizable aspects to simplify and streamline your operations. Running seamlessly in the background or on demand, these scripts revolutionize your workflow with minimal intervention."
            bullet1="Enable Two-Way Sync Between Systems"
            bullet2="Automatically Deliver Data to Your Email Inbox"
            bullet3="Automate Common or Recurring Tasks"
            subTitle="Trending" 
            flip={true}
          />
          <ImageTitleDescriptionBullets 
            id='ecommerce'
            featureImage={ECommerceWebsitesImage}
            title="E-Commerce Websites"
            description="Maximizing e-commerce success goes beyond offering a purchasing platform. It involves systematically guiding consumers to the right products, options, and upsells. Our designers, developers, and data analysts collaborate to personalize user journeys with advanced designs, features, and algorithms, all while ensuring the protection of your customers' sensitive information."
            bullet1="Integrate Existing POS/ERP Systems To Reduce Overhead"
            bullet2="Create New Opportunities With Carside Pickup or AR Demos"
            bullet3="A/B Test With Ease Utilizing Flexible Design Components"
            subTitle="" 
          />
          <ImageTitleDescriptionBullets 
            id='integrations'
            featureImage={IntegrationServicesImage}
            title="Integration Services"
            description="Constructing entirely custom software may not align with your organization's current needs. However, our experienced integration team can enhance your existing ERP, CRM, PSA, HMS, RPA, PMS, or other systems to suit your evolving operations. Whether integrating with physical hardware or adding capabilities to resolve workflow bottlenecks, the opportunity for future expansion doesn't necessitate a major overhaul."
            bullet1="Synchronize Multiple Systems Together"
            bullet2="Automate Compliance and Logistic Complexities"
            bullet3="Streamline Staff Onboarding with Workflow Optimization"
            subTitle="" 
            flip={true}
          />
          {/* <LoadableParallax /> */}
        </div>
      </Layout>

    </>
  )
}

export default SoftwareDevelopmentServicesPage