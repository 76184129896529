import React, { useEffect } from 'react';
import BtnArrow from "../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../assets/images/button_arrowbottom.svg"
import ImageAniamtion from "../assets/images/slider-button.svg"
import MarqueeImage2 from "../assets/images/capa_right2.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
// Install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar]);


function SoftwareCapabilites(){


    return (
    <section className="service-section capabilities-hero">
        
    <div className=" container pt-5">
      <div className="row justify-content-center align-items-center justify-content-lg-between ">
        <div className=" col-lg-12 col-xl-12 text-white order-lg-1 mb-5 mb-lg-0 service-top">
          <span className="badge bg-muted-purple text-white rounded-pill mb-2">
          Software Development Services
          </span>
          <h1>
          <span className='text-green'>Software</span> Built Around Your Needs
          </h1>
           <p>Our Software Development Services bring your unique vision to life while minimizing complexity and technical knowledge overhead. With a focus on consultancy, we assist in selecting the optimal technology and features within your budget, while also aiding in mapping out future functionality.</p>
          <a href="/contact/" className='button-link'>Contact Us
          <div className="arrow_movement">
               <img src={BtnArrow} alt=''/>
               <img src={BtnArrowbottom} alt=''/>
          </div>
          </a>
        </div>
      
      </div>
      
    </div>
    <div className='horizontal-slider'>
    <div class="m-scroll" data-cursorsize="large" >
        <div class="m-scroll__title">
            <div className='m-scroll__title-main horizontal-slider-swiper'>

            <Swiper
            spaceBetween={32}
            centeredSlides= {true}
            speed={4000}
            loop={true}
            autoplay={{
              delay: 0,
             
          }}  
          autoHeight = {true}
          slidesPerView = {"auto"}
          allowTouchMove ={false}
          disableOnInteraction = {false}
          className='capabilities-loop-slider'
        >   <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> React Native
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Node.js
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> MongoDB
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Ruby on Rails
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> MySQL
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Swift
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> Postgres
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> React.js
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> WordPress
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Drupal
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Rust
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Oracle SQL
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Python
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Gatsby.js
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Kotlin
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Electron
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Nuxt.js
            </div>
            </SwiperSlide>
            </Swiper>
            {/* <div className='ai-box'>
            <img src={ImageAniamtion} alt='' />  Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Java.js
            </div>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Angular.js
            </div> */}
            </div>
            </div>
            </div>
       </div>
  </section>
    )
}


export default SoftwareCapabilites;
